@import "~antd/dist/antd.less";

@color-primary: #0bafe0;
@color-secondary: #0e7bb3;
@color-feature-primary: #0bafe0;
@color-feature-secondary: #0e7bb3;
@color-tertiary: #5d6c76;
@color-accent: #f8f8f8;
@color-foreground: #ffffff;
@color-background: #ffffff;

/* TEXT */
@text-default: #242425;
@text-secondary: #fff;
@text-label: #808291;
@text-disabled: #e1dfe9;

/* SYSTEM COLORS */
@color-success: #7fba7a;
@color-warning: #fec22d;
@color-danger: #ff0000;

@sm1: 0.375rem;
@sm2: 0.625rem;
@sm3: 0.875rem;

/* MEDIUM */
@md1: 1rem;
@md2: 1.375rem;
@md3: 1.75rem;

/* LARGE */
@lg1: 2.5rem;
@lg2: 3.5rem;
@lg3: 4.5rem;

@first: #437ef7;
@second: #f7af43;
@third: #a843f7;

@shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

.sr-only {
  position: absolute;
  left: -100000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  position: relative;
  padding-right: 15px;
  height: 100%;
}
.container.unset {
  height: unset;
}
@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.ql-video {
  max-width: 100%;
}

.image__editor {
  display: flex;
  height: 128px;
  margin-bottom: @lg1;
  img,
  .img {
    flex-shrink: 0;
    width: 128px;
    height: 128px;
    margin-right: @md3;
    object-fit: cover;
    border-radius: 50%;

    @media (max-width: 992px) {
      width: 64px;
      height: 64px;
    }
  }
  .editor {
    height: 100%;
    width: 100%;
    max-width: 275px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      font-size: 1.2rem;
      font-weight: normal;
    }

    p {
      font-size: @sm3;
      color: #808291;
    }
  }
}

.onMobile {
  &--hide {
    display: inherit;
  }

  &--show {
    display: none;
  }
  @media (max-width: 992px) {
    &--hide {
      display: none;
    }

    &--show {
      display: inherit;
    }
  }
}

// .profile__card {
//   padding: @lg1 @md3;
//   border-radius: @sm2;
//   box-shadow: 0px 40px 32px -24px rgb(15 15 15 / 12%);
//   border: 1px solid #e6e8ec;
//   position: sticky;
//   top: @lg3;
//   margin-bottom: @lg1;
//   height: fit-content;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   text-align: center;
// }

.badge__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  p {
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .learningBadge {
    width: calc(@lg3 * 3);
    height: calc(@lg3 * 3);
    border-radius: 100%;
  }
}

.profile,
.person__modal {
  position: relative;
  min-height: 100vh;
  padding-top: 6rem;

  .profile__badge {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  .profile__header,
  .modal__header {
    width: 100%;
    height: 12rem;
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .profile__card,
  .person__card {
    padding: @lg1 @md3;
    border-radius: @sm2;
    box-shadow: 0px 40px 32px -24px rgb(15 15 15 / 12%);
    border: 1px solid #e6e8ec;
    position: sticky;
    top: @md2;
    margin-bottom: @lg1;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .profile__card {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      align-items: center;
    }
    .avatar {
      width: 10rem;
      height: 10rem;
    }

    @media (max-width: 992px) {
      display: flex;

      .details {
        text-align: left;
      }
      padding: @sm1;
      p,
      h2 {
        margin: 0;
        padding: 0;
      }
      .ant-card-body {
        gap: @md1;
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: row;
        padding: 0;
      }
      .avatar {
        margin: 1.2rem 0 0 0;
        height: 2rem;
        width: 2rem;
      }
    }
  }

  .profile__card--loading {
    width: 100%;
    height: 50vh;
    border-radius: @sm2;
    position: sticky;
    top: @lg3;
    margin-bottom: @lg1;
  }

  .person__content {
    margin-top: calc(@lg3 * 1.6);
  }
}

.filter {
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  padding: @sm2;
  margin: @md3 0 @md1 0;

  &.no-marg-top {
    margin-top: @sm1;
  }

  &__input {
    display: flex;
    align-items: center;
    flex-grow: 1;
    color: #808291;
    input {
      background-color: #f8f8f8;
      width: 100%;
    }

    &--noMargin {
      margin: 0;
    }
  }
  &__actions {
    display: flex;
    button {
      margin-left: @md1;
    }
  }
}

.hero {
  position: relative;
  padding: 2.5rem;
  width: 100%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 992px) {
    padding: 2.5rem 1rem;
  }

  .progress {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: @sm2;
    backdrop-filter: blur(8px);

    .percentage {
      height: 100%;
      background-color: @color-primary;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 65%;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(36, 36, 37, 1) 100%);
  }

  &.shadowless {
    &::after {
      display: none;
    }
  }
  .hero__content {
    width: 100%;
    z-index: 1;
    color: white;
    width: 45%;
    @media (max-width: 992px) {
      width: 70%;
    }
  }
}

.navbar {
  width: caLC(100vw - (@md1 * 2));
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: @sm1 @md2;
  border-bottom: 1px solid #f8f8f8;
  background-color: white;
  box-shadow: 0px 40px 32px -24px rgb(15 15 15 / 12%);
  position: fixed;
  top: @md1;
  left: @md1;
  right: @md1;
  z-index: 50;
  box-shadow: @shadow;
  border-radius: @sm2;

  .ant-menu-horizontal {
    border: none;
  }

  .navbar__list {
    display: flex;
    list-style-type: none;
    flex-grow: 1;
    justify-content: center;
  }

  .drawer__btn {
    display: none;
    @media (max-width: 992px) {
      display: flex;
      justify-content: flex-end;
    }
  }

  .nav__person {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 992px) {
      display: none;
    }
  }
}

.respCard,
.smallCard {
  box-shadow: @shadow;
  margin-bottom: @md3;

  @media (max-width: 769px) {
    padding-bottom: @md3;
  }

  &__image {
    height: 12.5rem;
    width: 100%;
    border-radius: @sm2;
    position: relative;
    overflow: hidden;

    @media (max-width: 769px) {
      margin-bottom: @md3;
    }

    .progress {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: @sm2;
      backdrop-filter: blur(8px);
      z-index: 2;

      .percentageNum {
        position: absolute;
        bottom: @md1;
        left: @sm1;
      }

      .percentage {
        height: 100%;
        background-color: @color-primary;
      }
    }
  }

  .content {
    margin: auto 0;
    padding-left: @md1 !important;
    display: flex;
    flex-direction: column;

    .expand {
      font-size: @sm2;
      font-weight: lighter;
    }

    .meta {
      position: relative;
      font-size: 12px;
      font-weight: 500;
      color: #808191;
      margin-right: 16px;
    }
  }
}

ul.learning--list {
  list-style: none;
  padding: 0;

  li,
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 62px;
    padding: @sm3;
    border-radius: @sm3;
    border: 1px solid #f8f8f8;
    margin-bottom: @sm2;
    transition: all 0.3s ease-in-out;

    @media (max-width: 769px) {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
    }

    div.details {
      display: flex;
      gap: 0.225rem;

      @media (max-width: 769px) {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
      }
    }

    &.completed {
      border: 1px solid @color-primary;
      background-color: rgba(@color-primary, 0.09);
    }

    &.failed {
      border: 1px solid @color-danger;
      background-color: rgba(@color-danger, 0.09);
    }

    &.hoverable {
      &:hover {
        cursor: pointer;
        border: 1px solid @color-primary;
        background-color: rgba(@color-primary, 0.02);
      }
    }
  }
}

.smallCard {
  box-shadow: @shadow;
  margin-bottom: @md3;
  padding-bottom: @md3;
  position: relative;
  overflow: hidden;

  &--locked {
    .smallCard__image {
      overflow: hidden;
      &::after {
        content: "";
        position: absolute;
        backdrop-filter: blur(8px) brightness(90%);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        border-radius: @sm2;
      }
      .lock {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: white;
        font-size: @lg1;
      }
    }
  }

  &__image {
    height: 12.5rem;
    width: 100%;
    border-radius: @sm2;
    margin-bottom: @md3;

    .progress {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: @sm2;
      backdrop-filter: blur(8px);

      .percentage {
        height: 100%;
        background-color: @color-primary;
      }
    }
  }

  .content {
    margin: auto 0;
    padding-left: @md1 !important;
    display: flex;
    flex-direction: column;

    .expand {
      font-size: @sm2;
      font-weight: lighter;
    }

    .meta {
      position: relative;
      font-size: 12px;
      font-weight: 500;
      color: #808191;
      margin-right: 16px;
    }
  }
}

.miniCard {
  height: 7rem;
  background-color: #f8f8f8;
  border-radius: @sm2;
  margin-bottom: @md2;
  display: flex;
  overflow: hidden;

  &__image {
    width: 7rem;
    height: 7rem;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .ant-card-body {
    padding: 0;
  }
}

.program__card {
  width: 100%;
  height: 75vh;
  margin-bottom: @lg3;
  padding: @lg2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  box-shadow: @shadow;

  .progress {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: @sm2;
    backdrop-filter: blur(8px);

    .percentage {
      height: 100%;
      background-color: @color-primary;
    }
  }

  .title {
    color: white;
    font-size: @md3;
    font-weight: bold;
    max-width: 65%;
    margin-bottom: @md1;
    overflow: hidden;

    @media (max-width: 769px) {
      max-width: 85%;
    }
  }

  > * {
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: 0;
    right: 0;
    left: 0;
    // background-color: orange;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(36, 36, 37, 1) 100%);
  }
}

.certificate__card {
  margin: initial;
  font-weight: bold;
  font-variant: initial;
  line-height: initial;
  position: relative;
  box-sizing: border-box;
  border-radius: @sm2;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 1.4142/1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: @lg2;

  @media (max-width: 640px) {
    padding-bottom: 2rem;
  }

  .ant-card-body {
    padding: 0;
    height: 55%;
    width: 80%;
    // background-color: pink;

    @media (max-width: 640px) {
      height: 50%;
    }
  }

  .cert__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
    height: 100%;
    // background-color: rebeccapurple;

    .person {
      // background-color: green;
      p {
        font-size: @md1;
        // margin: 0;
        margin-bottom: @sm2;

        @media (max-width: 768px) {
          font-size: @sm1;
          margin-bottom: 0;
        }
      }
      p.title {
        font-size: @md2;
        // margin: 0;

        @media (max-width: 768px) {
          font-size: @sm1;
        }
      }

      // p.descriptor {
      // }
      // span {
      //   position: relative;
      // }

      h2 {
        position: relative;
        font-size: @md3;

        &.thungela {
          &::after {
            position: absolute;
            content: "";
            width: 50%;
            height: 1px;
            background-color: @text-label;
            bottom: -@sm2;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        @media (max-width: 640px) {
          font-size: 1rem;
          margin: 0;
        }
      }
    }

    .cert__details {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: @md3;
      // background-color: pink;

      @media (max-width: 640px) {
        gap: @sm1;
      }

      span {
        margin: 0;

        @media (max-width: 640px) {
          font-size: @sm1;
        }
      }
      p.expiry {
        color: @text-label;
        font-weight: normal;
        @media (max-width: 640px) {
          font-size: @sm1;
        }
      }

      .cert__grid {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: @md2;

        .block {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: @sm1 0;
          width: 32.5%;
          flex-grow: 1;
          position: relative;
          height: 65%;

          @media (max-width: 640px) {
            padding: 5px 0;
          }

          &::after {
            content: "";
            position: absolute;
            bottom: -0.2rem;
            left: 0;
            right: 0;
            height: 1px;
            width: 100%;
            background-color: @text-label;
          }

          img {
            object-fit: contain;
            width: 100%;
            height: @md3;
          }
        }
        .stamp {
          flex: 1 0 0;
          // width: 35%;

          img {
            object-fit: contain;
            height: 6rem;
            width: 6rem;

            @media (max-width: 640px) {
              height: 3rem;
              width: 3rem;
            }
          }
        }
      }
    }
  }
}

.modal {
  .ant-modal-content {
    top: @md3;
    // bottom: @md3;
    margin-bottom: @lg3;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100vw;
    max-width: 960px;
    height: fit-content;
    overflow: hidden;
  }
  .modal__image {
    height: 40vh;
    width: 100%;
    object-fit: cover;
    margin-top: -@lg1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &--small {
      height: 20vh;
    }
  }

  .modal__body {
    margin-top: @sm2;
    padding: @md3;

    .desc {
      width: 75%;
      overflow: hidden;
    }
  }
}

.question__review {
  margin-top: @md2;
  padding: 0 @lg3;
}

.question__panel {
  height: fit-content;
  // background-color: @color-primary;
  background: var(--ant-primary-color);
  padding: @lg1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  height: 100vh;
  position: relative;
  @media (max-width: 992px) {
    height: fit-content;
  }
  .home {
    position: absolute;
    top: @lg1;
    left: @lg1;
  }
  .assessment__detail {
    text-transform: uppercase;
    font-size: @sm3;
    position: relative;
    padding-bottom: @sm2;
    margin-bottom: @md1;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: @lg1;
      height: 1px;
      background: white;
    }
  }
  h2,
  p {
    color: white;
  }
  h2 {
    margin-bottom: @lg1;
  }
  .progress {
    width: 85%;
    height: 7px;
    border-radius: @sm3;
    background-color: #ffffff52;
    position: relative;
    p {
      position: absolute;
      top: -1.2rem;
      right: 0;
      font-size: @sm2;
    }
    .total {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      height: 7px;
      background-color: white;
      border-radius: @sm3;
    }
  }
}

.answer__panel {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  &--review {
    scroll-snap-type: none;
  }

  .assessment__header {
    padding-left: @md3;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: @sm1;
      background-color: var(--ant-primary-color);
      border-radius: 8px;
    }
  }

  .question {
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: @lg3;
    min-height: calc(100vh - 105px);

    &__image {
      width: 75%;
      aspect-ratio: 16/9;
      object-fit: contain;
      justify-self: start;
      border-radius: @md1;
      margin-bottom: @md1;
      overflow: hidden;
    }

    &--review {
      min-height: fit-content;
      height: fit-content;
      scroll-snap-align: unset;
      padding: @md1 @lg3 @md1 @lg3;
    }

    .type-icon {
      background-color: rgba(25, 151, 247, 0.1);
      color: @color-primary;
      padding: @md1;
      border-radius: 50%;
      margin-bottom: @sm3;
    }
    h3 {
      margin-bottom: @md1;
      font-size: @md3;
    }
    .divider {
      width: 65%;
      height: 1px;
      background-color: #808191;
    }
    .instruction {
      font-size: @sm2;
      text-transform: uppercase;
      color: @text-label;
      margin-top: @md2;
      margin-bottom: @sm1;
    }
    .match__list {
      display: flex;
      flex-direction: column;
      gap: @md2;
      .list__item {
        width: 100%;
        display: flex;
        align-items: center;

        .missing__opt {
          white-space: pre-line;
          flex-grow: 1;
        }
      }
    }
    .dropzone {
      min-width: @lg3;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin-top: @lg1;
      align-items: center;

      &--single {
        margin-top: 0;
      }

      &.dragging {
        border-radius: @sm2;
        background-color: rgba(@color-primary, 0.1);
        border: 1px dashed rgba(@color-primary, 0.587);
      }

      &--smallMTop {
        margin-top: @sm1;
      }

      &.empty {
        border: 1px dashed @text-label;
        border-radius: @sm2;
        min-height: calc((@sm2 * 2) + 12px * 2);
        padding: @sm1;
      }
    }

    .missing__opt {
      white-space: pre-line;
      padding: @sm2 @md1;
      border: 1px solid @color-primary;
      border-radius: @sm2;
      // transition: all 0.2s ease-in;
      margin: 0 @sm1;

      &--input {
        padding: 0;
      }

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &--column {
        border: 1px solid @text-label;
        border-radius: 0;
        border-top-left-radius: @sm2;
        border-bottom-left-radius: @sm2;
      }
      &--selected {
        margin-left: @sm2;
      }
      &--correct {
        background-color: rgba(@color-success, 0.1);
        border: 1px solid rgba(@color-success, 0.587);
        &:hover {
        }
      }
      &--wrong {
        background-color: rgba(@color-danger, 0.1);
        border: 1px solid rgba(@color-danger, 0.587);
        &:hover {
        }
      }

      &--vertical {
        margin: 0;
      }

      &.dragging {
        background-color: rgba(@text-default, 0.1);
        border: 1px dashed rgba(@text-default, 0.587);
      }

      &:hover {
        background-color: rgba(25, 151, 247, 0.1);
        border: 1px solid rgba(25, 151, 247, 0.587);
        cursor: pointer;
      }
    }

    .quiz__opt {
      margin-bottom: @sm2;
    }
    .context {
      font-size: @md1;
      margin-bottom: @md2;
    }
    .assessment__action {
      margin-top: @lg1;
    }
    button.next {
      margin-top: @lg1;
    }
  }

  .option__choice {
    display: flex;
    width: 100%;
    padding: @md2;
    border-radius: @sm2;
    box-shadow: @shadow;
    border: 1px solid white;
    white-space: pre-line;

    &:hover {
      cursor: pointer;
    }

    &--correct {
      background-color: rgba(@color-success, 0.1);
      border: 1px solid rgba(@color-success, 0.587);
      &:hover {
      }
    }
    &--wrong {
      background-color: rgba(@color-danger, 0.1);
      border: 1px solid rgba(@color-danger, 0.587);
      &:hover {
      }
    }
  }
}
.row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  height: 100vh;
  &.noGap {
    grid-gap: 0;
  }
}
.feedback--modal {
  background: rgb(255, 255, 255);
  width: 100vw;
  max-width: 960px;
  // margin: 2rem auto;;
  border-radius: 1rem;
  overflow: hidden scroll;
  position: fixed;
  top: var(--md3);
  bottom: var(--md3);
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 102;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &--small {
    max-height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    img.success {
      width: 100%;
      height: 15rem;
      object-fit: contain;
      background-color: green;
    }
  }
  .image-panel {
    height: 100%;
    width: 100%;
    background-image: url("https://cdn.dribbble.com/users/3956332/screenshots/15395255/media/609851247fbd2100dd0046405576f746.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .feedback {
    padding: var(--lg2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    p.message {
      margin: var(--md1) 0 var(--lg3) 0;
    }
    form {
      margin-top: var(--sm2);
      display: flex;
      flex-direction: column;
      textarea {
        height: initial;
        font-weight: normal;
        font-size: var(--sm3);
        margin-bottom: var(--md3);
      }
      .section {
        font-size: var(--md1);
        font-weight: bolder;
        margin-bottom: var(--md1);
      }
      .radio-group {
        display: flex;
        margin-bottom: var(--md3);
        label {
          margin-right: var(--md1);
          display: inline-flex;
          align-items: center;
          padding: 10px 16px;
          border-radius: 50em;
          cursor: pointer;
          transition: 0.2s;
          border: 2px solid #d3d3d4;
          font-size: var(--sm3);
          &::before {
            content: "";
            border-radius: 50%;
            height: var(--md1);
            width: var(--md1);
            border: 2px solid #d3d3d4;
            margin-right: 0.5rem;
          }
        }
        input:checked + label {
          border-color: var(--color-primary);
          box-shadow: 0 0 0 2px hsla(220, 90%, 56%, 0.2);
          background-color: rgba(25, 151, 247, 0.1);
          &::before {
            content: "";
            border-radius: 50%;
            height: var(--md1);
            width: var(--md1);
            border: 2px solid var(--color-primary);
            background-color: var(--color-primary);
            margin-right: 0.5rem;
          }
        }
      }
    }
    .button-group {
      display: flex;
      gap: var(--md1);
      button,
      a {
        width: 50%;
        max-width: unset;
      }
    }
  }
}

.fill__missing {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: @lg1;
  gap: @md2;
  min-height: calc((@sm2 * 2) + 1.5rem);
  border-radius: @sm2;
  margin-bottom: @sm2;

  &--inline {
    align-items: center;

    p {
      margin: 0;
    }
  }

  &.empty {
    border: 1px dashed @text-label;
  }
  &.noMargin {
    margin: 0;
  }
  &.column {
    flex-direction: column;
    margin: 0;
    height: 100%;
  }
}
.missing__opt,
.quiz__opt {
  padding: @sm2 @md1;
  border: 1px solid @color-primary;
  white-space: pre-line;
  border-radius: @sm2;
  // transition: all 0.2s ease-in;
  &--selected {
    margin-left: @sm2;
  }
  &:hover {
    background-color: rgba(25, 151, 247, 0.1);
    border: 1px solid rgba(25, 151, 247, 0.587);
    cursor: pointer;
  }
  &--correct {
    background-color: rgba(@color-success, 0.1);
    border: 1px solid rgba(@color-success, 0.587);
    &:hover {
      background-color: rgba(@color-success, 0.1);
      border: 1px solid rgba(@color-success, 0.587);
    }
  }
  &--wrong {
    background-color: rgba(@color-danger, 0.1);
    border: 1px solid rgba(@color-danger, 0.587);
    &:hover {
      background-color: rgba(@color-danger, 0.1);
      border: 1px solid rgba(@color-danger, 0.587);
    }
  }
}

.quiz__opt {
  margin-top: @md1;
}

.auth {
  width: 100vw;
  min-height: 100vh;
  // background-image: url('');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 100;
  @media (max-width: 992px) {
    padding: @lg1;
    // background-color: orange;
  }

  .companyLogo {
    // position: absolute;
    // bottom: @lg1;
    // right: @lg1;
    align-self: center;
    // width: calc(@lg3 * 1.8);
    height: calc(@lg3 * 1.8);
    margin-bottom: @md3;
  }
  &__pannel {
    margin: 1vw;
    width: 100%;
    height: calc(100vh - 2vw);
    border-radius: @sm2;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: scroll;
    /* backdrop-filter: blur(1.5rem) saturate(100%) contrast(75%) brightness(130%); */
    backdrop-filter: blur(1.5rem);
    background-color: rgba(0, 0, 0, 0.15);
    padding: @lg1;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: @md3;
      font-weight: bold;
      margin-bottom: @sm2;
    }
    p {
      font-size: @sm3;
      color: white;
      margin-bottom: @md1;
    }
    .auth__divider {
      color: var(--text-color-base);
      text-transform: capitalize;
      font-size: @sm3;
      margin: @md2 0;
      text-align: center;
    }
    form {
      display: flex;
      flex-direction: column;
      .input {
        width: 100%;
        margin-bottom: @md1;
      }
      .form__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: @md1;
        .checkbox__label {
          font-size: @sm3;
          color: white;
        }
      }
    }
    @media (max-width: 992px) {
      border-radius: @sm2;
      height: calc(100vh - (@md2 * 2) - @lg1);
    }
  }
  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: @lg1;
    height: fit-content;
    align-items: center;
    button {
      margin-left: @md1;
    }
  }
}
.z-2 {
  z-index: 2;
}

.channel--sidebar {
  overflow: hidden scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-right: 1px solid #f8f8f8;
  padding: @md3;

  height: calc(100vh - ((@sm1) * 2 + (@sm2 * 2) + 22px));

  &--drawer {
    padding: 0;
    border: none;
  }

  .channel {
    display: flex;
    align-items: center;
    padding: @sm3;
    border-radius: @sm2;
    margin-bottom: @md1;
    transition: all 0.2s ease-in;
    position: relative;
    &:hover {
      background-color: #f8f8f8;
      cursor: pointer;
    }
    &.active {
      background-color: rgba(25, 151, 247, 0.1);
      border: thin solid rgba(25, 151, 247, 0.333);
    }
    &__image {
      position: relative;
      flex-shrink: 0;
      width: @lg1;
      height: @lg1;
      margin-right: @md1;
      img {
        width: 100%;
        height: 100%;
        border-radius: @sm2;
        object-fit: cover;
      }
    }
    &__content {
      flex: 1;
      overflow: hidden;

      p {
        margin: 0;
      }

      .title {
        width: 85%;
        font-weight: bold;
        font-size: @sm3;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .extras {
        display: flex;
        justify-content: space-between;

        .message {
          color: #808291;
          font-size: @sm3;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .date {
          margin-left: @sm1;
          color: #808291;
          font-size: @sm3;
        }
      }
    }
  }
}

.policy {
  width: calc(100% + (1.75rem) * 2);
  background: rgb(238, 245, 254);
  background: linear-gradient(90deg, rgba(238, 245, 254, 1) 0%, rgba(255, 255, 255, 0.6110819327731092) 100%);
  padding: @md3;
  margin-left: -1.75rem;
  margin-bottom: @md3;
  .title {
    color: @color-primary;
    font-size: @md1;
    margin-bottom: @sm2;
  }
  .policy-message {
    font-size: @sm3;
    color: #7d9aae;
  }
}

.chat--wrapper {
  width: 100%;
  padding-top: 5.95rem;
  height: 100vh;

  @media (max-width: 992px) {
    padding-top: 0;
  }
}

.chat--content {
  padding: 3rem @md3 0 @md3;

  @media (max-width: 576px) {
    padding: 1rem;
  }
}

.message--list {
  // flex: 1 auto;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;

  .log {
    color: @text-label;
    font-size: @sm2;
    margin: 0 auto;
    margin-bottom: @md1;
  }

  .message {
    max-width: 65%;
    border-radius: @sm2;
    position: relative;
    padding: @sm2 @md1;
    display: flex;
    align-items: center;
    margin-bottom: @md2;
    font-size: @sm3;
    text-wrap: wrap;

    @media (max-width: 576px) {
      max-width: 100%;
    }

    img.sender {
      position: absolute;
      top: calc((0.7rem + 0.625rem) - 0.875rem);
      width: @md3;
      height: @md3;
      border-radius: 50%;
      object-fit: cover;

      @media (max-width: 576px) {
        display: none;
      }
    }
    &__incoming {
      background-color: @color-accent;
      color: @text-default;
      align-self: flex-start;
      margin-left: calc(@md3 + @sm1);
      img {
        left: -2.125rem;
      }
      @media (max-width: 576px) {
        margin: 0;
      }
    }
    &__outgoing {
      color: white;
      background-color: @color-primary;
      align-self: flex-end;
      margin-right: calc(@md3 + @sm1);
      img {
        right: -2.125rem;
      }
      @media (max-width: 576px) {
        margin: 0;
      }
    }
    &--grouped {
      margin-bottom: @sm1;
    }
    &--image {
      flex-direction: column;
      align-items: flex-start;
      background-color: transparent;
      border: 1px solid @text-disabled;

      p.caption {
        font-size: @sm2;
        color: @text-label;
      }

      img.media {
        aspect-ratio: 16/9;
        height: calc(@lg3 * 3);
      }
    }
  }
}

.footer--input {
  position: fixed;
  margin-left: 1.75rem;
  bottom: 1rem;
  @media (max-width: 992px) {
    bottom: 4rem;
  }
}
.chat--details {
  padding: @md2;
  // padding-top: 5.75rem;
  border-left: 1px solid @text-disabled;
  // @media (min-width: 992px) {
  //   display: none;
  // }
}
.chat--content {
  height: calc(100vh - 11.7rem);
  overflow: hidden scroll;
  @media (max-width: 992px) {
    height: calc(100vh - 8.8rem);
  }
}
// REUSABLES
.badge--meta {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  color: #808191;
  margin: 0 16px 0 0;

  &--noSpace {
    margin: 0;
  }
}

.bg--boilerplate {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.hide--onMobile {
  @media (max-width: 769px) {
    display: none;
  }
}
.show--onMobile {
  display: none;
  @media (max-width: 769px) {
    display: initial;
  }
}
.hide--aboveMedium {
  @media (max-width: 992px) {
    display: none;
  }
}
.show--belowMedium {
  display: none;
  @media (max-width: 992px) {
    display: initial;
  }
}

.capitalize {
  text-transform: capitalize;
}

.base--shadow {
  box-shadow: @shadow;
}

// ANT RESETS
.modal--noPadding {
  .ant-modal-body {
    padding: 0 !important;
  }
}

.no-padding .ant-collapse-item .ant-collapse-header {
  padding: 0px !important;
}

.collapse-center .ant-collapse-item .ant-collapse-header {
  display: flex;
  align-items: center;
}

.site-layout .lesson__header {
  background: #fff;
}

.dropdown--tree {
  .ant-tree-treenode-switcher-open.ant-tree-treenode-selected,
  .ant-tree-treenode-selected {
    &::before,
    &::after {
      background: rgba(@color-primary, 0.3) !important;
      border-radius: @sm2;
      border: 1px solid @color-primary;
    }
  }
}

.leasson__header {
  padding: @md1 @lg1 @md1 @lg1;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #eaeaea;
  background-color: #f8f8f8;
  z-index: 1;
  // filter: drop-shadow(0 35px  #f8f8f8);

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .progress {
    margin-top: @md1;
    width: 100%;
    height: 4px;
    border-radius: @sm3;
    background-color: #eaeaea;
    position: relative;

    .total {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      height: 4px;
      background-color: var(--ant-primary-color);
      border-radius: @sm3;
    }
  }
}

.draggable {
  -webkit-user-drag: element;
}

.lesson__container {
  background-color: "#f8f8f881";
  padding: 0 @lg1;

  @media screen and (max-width: 1024px) {
    padding: 0;
  }

  &--pdf {
    width: 100%;
    position: relative;

    .pdf__container {
      width: 100%;
      height: calc(100vh - 141px);
      overflow: scroll;

      @media (max-width: 768px) {
        height: calc(100vh - 56px);
      }
    }

    .header {
      width: 100%;
      opacity: 0.9;
      // width: calc(100% + 80px);
      // margin-left: -40px;
      background-color: #f8f8f8;
      box-shadow: @shadow;
      position: fixed;
      z-index: 100;
      // top: 5.26rem;
      left: 0;
      right: 0;
      bottom: 0;
      padding: @md1 @lg3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: @md1;

      @media (max-width: 768px) {
        // top: 0;
      }
    }
  }

  &--scorm {
    padding: 0;
    iframe {
      width: 100%;
      margin: auto;
      height: calc(100vh - 85px);
    }
  }
  &--scormFull {
    padding: 0;
    iframe {
      width: 100%;
      margin: auto;
      height: 100vh;
    }
  }

  &--page {
    padding-top: @lg2;
  }

  &--card {
    padding: 0 @md1;
  }
}
.cards__carousel {
  @media (max-width: 992px) {
    position: fixed;
    top: 30%;
    transform: translateY(30%);
  }

  ul.slick-dots {
    top: -1rem;
    li {
      background: @text-disabled;

      &.slick-active {
        button {
          background: var(--ant-primary-color);
        }
      }
    }
  }
}

.video__card {
  // height: calc(100vh - 15rem);
  // overflow: hidden scroll;
  position: relative;
  // z-index: 2;

  video {
    // width: 100%;
    // height: 100%;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    border-radius: @sm2;
  }
}

.ql-editor .interactive {
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--drop-shadow);
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.ql-editor .interactive h2 {
  font-size: medium;
  border-bottom: 1px solid #eee;
  margin-bottom: 2rem;
  padding: 2rem;
}
.ql-editor .interactive > div {
  padding: 1rem;
}

.ql-editor .interactive > div:last-of-type div.selected::before {
  border-color: var(@color-primary);
  background-color: var(@color-primary);
}
.ql-font span[data-label="Poppins"]::before {
  font-family: "Poppins";
}
.ql-font span[data-label="Sans Serif"]::before {
  font-family: sans-serif;
}
.ql-font span[data-label="Roboto"]::before {
  font-family: "Roboto";
}
.ql-editor h3 {
  font-size: x-large;
}
.ql-editor h4 {
  font-size: larger;
}
.ql-editor h5 {
  font-size: large;
}
.ql-editor h6 {
  font-size: medium;
}

.ql-font-roboto {
  font-family: "Roboto";
}
.ql-editor .ql-video {
  width: 100%;
  aspect-ratio: 16/9;
  // min-height: 60vh;
}
.ql-editor .ql-image {
  max-width: 100%;
}
.ql-editor iframe.ql-iframe {
  width: 100%;
  aspect-ratio: 16/11;
}
.ql-editor.learning__card {
  width: 100%;
  overflow: hidden scroll;
  text-align: initial;
  position: relative;

  &--portrait {
    aspect-ratio: 4/5;
  }
  &--landscape {
    aspect-ratio: 16/9;
  }

  img.background {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
  }
  iframe {
    width: 100%;
    border-radius: var(@sm2);
    border: none;
    aspect-ratio: 16/9;
    margin: 0;
  }

  *:not(div),
  img:not(.background) {
    position: relative;
    z-index: 1;
    margin: 0 0 0 0;
  }

  .ql-image:not(.background) {
    max-width: 100%;
  }
}

.page__learning {
  padding-top: @lg2;
}

section.featured {
  overflow: scroll hidden;
  padding-bottom: @md3;
  display: flex;
  margin-bottom: @md1;
  transition: all 0.1s ease;

  .card {
    min-height: 13rem;
    aspect-ratio: 4 / 3;
    min-width: 40%;
    background: white;
    border-radius: @sm2;
    box-shadow: @shadow;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
    padding: @lg1 @md3;
    margin-right: @md1;

    @media (max-width: 992px) {
      min-width: calc(85vw);

      .title {
        margin-bottom: 0;
      }
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 60%;
      bottom: 0;
      right: 0;
      left: 0;
      // background-color: orange;
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(36, 36, 37, 1) 100%);
    }

    * {
      z-index: 1;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    p,
    a {
      z-index: 1;
      color: white;
    }
    p.title {
      font-size: @md2;
      font-weight: bold;
      width: 75%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    p.desc {
      font-size: @sm3;
      margin-bottom: @sm2;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      width: 90%;
    }
  }
}

.ellipses--two {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.chat__person {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: @sm2;
  border: 1px solid @color-accent;
  margin-bottom: @sm2;

  &:hover,
  &--selected {
    background-color: @color-accent;
    border: 1px solid @color-primary;
  }
}
.rating {
  position: "relative";
  margin-top: 12px;
}
.lesson__footer {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 992px) {
    padding: @md1;
  }

  &.non-fixed {
    position: relative;
  }
}
.learning__sidebar {
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100vh;

  @media (max-width: 1200px) {
    display: none;
  }

  .ant-layout-sider-zero-width-trigger {
    z-index: 999;
    top: 6.5rem;
  }

  .sidebar__actions {
    gap: 1rem;
    flex-direction: column;
    margin: 0;
    display: none;

    @media (max-width: 769px) {
      display: flex;
    }
  }

  .lesson__items {
    overflow: scroll;
    height: 85vh;

    @media (max-width: 992px) {
      height: auto;
    }
  }
}

.learning__content {
  background: rgba(0, 0, 0, 0.02);
  height: 100vh;
  overflow: hidden scroll;
  width: 100%;
}

.lesson__button {
  padding: @sm3;

  .title {
    font-size: @md1;
  }
  .description {
    font-size: @sm3;
  }

  .ant-card-body {
    padding: 0;
  }

  .button__content {
    @media (max-width: 992px) {
      display: none;
    }
  }
  &--next {
    .title,
    .description {
      color: white;
    }
    .ant-card-meta-description,
    .ant-card-meta-title {
      color: white !important;
    }

    .icon {
      background: var(--ant-primary-color-hover);
    }
    background: var(--ant-primary-color);
    &:hover {
      border: 1px solid @color-primary;
    }
  }
  &--prev {
  }
}

.lesson__next {
  background: var(--ant-primary-color);

  .ant-card-meta-description,
  .ant-card-meta-title {
    color: white !important;
  }

  &:hover {
    border: 1px solid @color-primary;
  }
}

// ================
.ant-btn-primary {
  border-color: var(--ant-primary-color);
  background: var(--ant-primary-color);

  &:hover {
    background-color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-outline);
  }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: var(--ant-primary-color);
  background: var(--ant-primary-color);

  &:hover {
    border-color: var(--ant-primary-color-outline);
    background-color: var(--ant-primary-color-hover);
  }
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: var(--ant-primary-color);

  &:hover {
    color: var(--ant-primary-color-hover);
  }
}

.ant-menu {
  .ant-menu-item-selected {
    &::after {
      position: absolute;
      right: 20px;
      bottom: 0;
      left: 20px;
      transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      border-bottom: 2px solid var(--ant-primary-color) !important;
      content: "";
    }
  }
}

.ant-menu-horizontal > .ant-menu-item.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  &:hover {
    a {
      color: var(--ant-primary-color-hover);
    }

    &::after {
      position: absolute;
      right: 20px;
      bottom: 0;
      left: 20px;
      transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      border-bottom: 2px solid var(--ant-primary-color-hover) !important;
      content: "";
    }
  }
}

.ant-layout-sider-zero-width-trigger {
  z-index: 10000;
}
.chat__yellow--icon {
  position: relative;
  color: var(--ant-primary-color);

  &:hover {
    color: var(--ant-primary-color-hover);
  }

  &.alert {
    &::after {
      content: "";
      position: absolute;
      top: 0.7rem;
      right: 0.7rem;
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background-color: red;
    }
  }
}

.menu__dropDown {
  color: var(--ant-primary-color);
  background-color: var(--ant-primary-color-outline);
}

.form__item--noMargin {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.chat-sidebar {
  padding-top: 2.5rem;
}

.review__button {
  padding: @sm2 @md2;
  border-radius: @sm2;
  margin-bottom: @md1;
  width: fit-content;
  background: rgba(255, 255, 255, 0.11);
  border: 1px solid rgba(255, 255, 255, 0.308);
  backdrop-filter: blur(8px);
}

.podium {
  height: 45vh;
  display: flex;
  gap: @md1;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;

  @media (max-width: 992px) {
  }
  @media (max-width: 992px) {
    flex-direction: column;
    height: fit-content;
    align-items: flex-start;
  }

  &__item {
    width: 33%;
    border-radius: @sm2;
    position: relative;
    padding: @sm2;
    display: flex;
    flex-direction: column;

    &--1 {
      @media (max-width: 992px) {
        height: 200px;
        width: 98%;
      }
      height: 98%;
      background-color: @second;
    }
    &--2 {
      @media (max-width: 992px) {
        height: 200px;
        width: 83%;
      }
      height: 83%;
      background-color: @first;
    }
    &--3 {
      @media (max-width: 992px) {
        height: 200px;
        width: 68%;
      }
      height: 68%;
      background-color: @third;
    }
  }

  &__header {
    position: relative;
    z-index: 1;

    &__standing {
      font-size: @sm2;
      text-transform: uppercase;
      color: white;
      margin: 0;
    }
    &__person {
      color: white;
      margin: 0;
    }

    img {
      @media (max-width: 992px) {
        height: 80px;
        top: -1.8rem;
        right: -1rem;
      }
      position: absolute;
      // right: -1rem;
      right: 0;
      top: -2.5rem;
    }
  }

  &__body {
    padding: @md1;
    display: flex;
    align-items: flex-end;
    position: relative;
    border-radius: @sm2;
    height: 100%;
    margin-top: @md1;
    background: repeating-linear-gradient(
      45deg,
      rgba(black, 0.15),
      rgba(black, 0.15) 3px,
      transparent 3px,
      transparent 9px
    );
  }

  &__badge {
    margin: 0;
    padding: @sm1 @md1;
    border: 1px solid rgba(white, 25%);
    background: rgba(white, 25%);
    backdrop-filter: blur(2px);
    border-radius: 5rem;
    color: white;
    font-size: @sm2;
  }
}

.mobileNav {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.08);
  position: fixed;
  bottom: @sm2;
  width: calc(100vw - 2rem);
  box-shadow: @shadow;
  z-index: 100;
  padding: @sm1;
  border-radius: @md1;
  left: 50%;
  transform: translateX(-50%);
}

.mobileProfile {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: fixed;
  z-index: 100;
  top: @md1;
  right: @md1;
  border-radius: @lg1;
  padding: @sm1 @sm3;
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: @sm2;

  @media (max-width: 992px) {
    display: flex;
  }
}
.floatingBack {
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  z-index: 100;
  top: @md1;
  left: @md1;
  border-radius: @lg1;
  padding: @sm1 @sm3;
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: @sm2;
  color: white;

  &--dark {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: white;
    cursor: pointer;
  }

  &--fixed {
    position: fixed;
  }

  @media (max-width: 992px) {
    display: flex;
  }
}

@primary-color: #0bafe0;@border-radius-base: 0.625rem;@height-base: 40px;